import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
  return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetDDLData(params) {  
    return await http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async GetCompanyLocation(params){   
    return await http.instance.get("/HrReports/GetCompanyLocation?" + params);
  }
  async GetUserListByLocationId(params){   
    return await http.instance.get("/HrReports/GetUserListLocationId?" + params);
  }
  async GetLeaveType(params){   
    return await http.instance.get("/HrReports/GetLeaveType?" + params);
  }
  async GetUserList(params) {  
    return await http.instanceHR.get("/Form/GetUserList?" + params);
  }
  async GetProjectList(params) {  
    return await http.instance.get("/HrReports/BindProjectDropdownByCompanyIdAndUserId?" + params);
  }
  async GetApprover(params) {  
    return await http.instance.get("/HrReports/GetApproverByApprovalType?" + params);
  }
  async GetApprovalGroup(params) {  
    return await http.instance.get("/HrReports/LoadApprovalGroup?" + params);
  }
  async GetStatusList(params) {  
    return await http.instance.get("/HrReports/GetStatus?" + params);
  }
  async GetEmployeeByCompanyId(params){     
    return await http.instanceHR.get("/Form/GetLicencedUsers?" + params);
  }
  async ReportMain(params) {
    return await http.instance.post("/Report/ReportMain", { postString: params });
  }
  async GetYears(params){     
    return await http.instance.get("/HrReports/GetCompanySessionYear?" + params);
  }
  async GetLocationByuserId(params) {
      ;
    return await http.instanceAsset.get("/AssetAssignment/GetLocationByuserId?" + params
    );
  }
  async AssignGetAssetType(params) {   
    return await http.instanceAsset.get("/AssetAssignment/GetAssetType?" + params);
  }
  async GetIssuedUser() {
    return http.instanceAsset.get("/LoadIssuedUser?");
  }
  GetAssetCatalogueType(param) {
    return http.instanceAsset.get("/GetAssetCatalogueType?" + param);
  }
  GetRelatedCatalogues(param) {
    return http.instanceAsset.get("/GetRelatedCatalogues?" + param);
  }
  async GetUserStatusitemlog() {
    return http.instanceAsset.get("/GetAllAssetOperationCodes?");
  }
  async GetTagsByModuleId(param) {
    return await http.instanceAsset.get("/GetTagsByModuleId?" + param);
  }
  GetUserLicenseModulesReport(){
    return http.instanceCRM.get("/GetUserLicenseModulesReport");
  }
  GetCompanyReportDDLTypes(param){
    return http.instanceCRM.get("/GetCompanyReportDDLTypes?"+param);
  }
  GetCompanyLeadOwners(param)
  {
    return http.instanceCRM.get("/GetCompanyLeadOwners?"+param);
  }
  GetCompanyClientsByCompanyId()
  {
    return http.instanceCRM.get("/GetCompanyClientsByCompanyId");
  }
  GetDDLStages()
  {
    return http.instanceCRM.get("/GetDDLStages");
  }
  GetCompanyDealOwners(param)
  {
    return http.instanceCRM.get("/GetCompanyDealOwners?"+param);
  }
  GetProjectByCompanyIdAndUserId(){  
      ;
    return http.instanceExpense.get("/ProjectDropdownByCompanyIdAndUserId");
  }
  GetAllCategoriesByCompanyId(){
      ;
    return http.instanceExpense.get("/GetAllCategoriesByCompanyId");
  }
  GetUsersByProjectIds(params) {
    return  http.instanceExpense.get("/GetUsersByProjectIds?" + params);
  }
  GetAllCategoriesByCompanyId(){
    return http.instanceExpense.get("/GetAllCategoriesByCompanyId");
  }
  GettTripListingddl() {   
    return  http.instanceExpense.get("/GetTripName/");
  }
  ExpenseGetUserListing(params) {         
    return  http.instanceExpense.get("/GetUsersByCompanyId/", { params });
  }
  
  async GetClientList(params) {
    return await http.instanceFsm.get("/Ticketing/GetCompanyClient?" + params);
}
async GetPrioritybyCompanyId(params) {
  return await http.instanceFsm.get("/Ticketing/GetPrioritybyCompanyId?" + params);
}
async GetDepartmentListbyCompanyForClient() {
  return await http.instanceFsm.get("/Ticketing/GetDepartmentListbyCompanyForClient");
}
async FetchTicketStatusList(params) {
  return await http.instanceFsm.get("/Ticketing/GetTicketStatusMapping?" + params);
}
async GetTicketStatus() {
  return await http.instanceFsm.get("/Ticketing/GetTicketStatus");
}
async GetDDLCategoryForTicket(params) {
  return await http.instanceFsm.get("/Ticketing/GetDDLCategoryForTicket?" + params);
}
async GetProductsList() {
  return await http.instanceFsm.get("/Ticketing/GetProductsList");
}
async GetDDLChannelByCompanyId(params) {
  return await http.instanceFsm.get("/Ticketing/GetDDLChannelByCompanyId?" + params);
}
async GetApprovalTypes(param) {
    ;
  return await http.instanceApproval.get("/ApprovalRule/GetApprovalTypes?"+ param );
}
async GetApprovalChainsByCompanyId() {
  return await http.instanceApproval.get("/ApprovalRule/GetApprovalChainsByCompanyId?");
}
async LoadUsersByCompanyId(param) {
  return await http.instanceApproval.get("/ApprovalRule/LoadUsersByCompanyId?"+ param);
}
async GetLeaveTypes(params){   
    
  return await http.instance.get("/BIReport/GetLeaveTypes?" + params);
}
async GetUsers(params){   
    debugger
  return await http.instance.get("/BIReport/LoadUser?" + params);
}
async GetChannel(){   
    
  return await http.instance.get("/BIReport/ReportChannel");
}
async GetDepartments(){   
  return await http.instance.get("/BIReport/GetDepartmentByUserId");
}
async GetClientListByProjectId (params){   
  return await http.instance.get("/BIReport/GetClientListByProjectId?" + params);
}
async GetTaskByProject (params){   
  return await http.instance.get("/BIReport/ListProjectsandTasks?" + params);
}
async GetApplication (params){
  return await http.instance.get("/BIReport/GetApplicationByProjectType?" + params);
}
async GetGetDeviceOrOS (params){   
  debugger;
  return await http.instance.get("/BIReport/GetDeviceOrOS?" + params);
}

}
export default new DataService();
